import React, { useContext, useState, useEffect } from "react";
import { FormattedNumber } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
import { FiRefreshCcw } from "react-icons/fi";
import { CgTrash } from "react-icons/cg";

import { I18nContext } from "../../../i18n";
import * as classes from "./Styles/Basket.module.css";

import { useDispatch } from "react-redux";
import handleUpdateProduct from "../handleUpdateProduct";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const renderProductImage = (itemCode, title) => {
  return (
    <div className="minicart-product-image-wrapper">
      <img
        style={{
          display: "inline-block",
          maxWidth: "100",
          height: "auto",
          padding: 4,
          lineHeight: "1.42857143",
          backgroundColor: "#fff",
          border: "1px solid #ddd"
        }}
        className="minicart-product-image "
        src={`https://ik.imagekit.io/ofb/store/20180522154/assets/items/largeimages/${itemCode}.jpg?tr=w-100,h-100,dpr-2,pr-true,f-auto`}
        alt={`${title}`}
      />
    </div>
  );
};

const Item = ({ item }) => {
  const suppliersBasketState = useSelector(
    state => state.sessionReducer.suppliersBasket,
    shallowEqual
  );
  const { currency, priceConvert } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [qtyVal, setQtyVal] = useState(1);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [updateBtn, setUpdateBtn] = useState(false);

  useEffect(() => {
    setQtyVal(item.qty);
    return () => {
      setQtyVal(1);
    };
  }, [item]);

  const removeFromBasket = number => null;

  return (
    // <div className="relative flex items-center w-full leading-snug text-center align-middle p-8-p">
    //   <span>{item.compCode}</span>

    //   <a className="w-3/12">{renderProductImage(item.itemcode, item.title)}</a>
    //   <p className="flex flex-col items-start w-6/12">
    //     <span className="font-bold text-black uppercase text-17-p">
    //       {item.title}
    //     </span>
    //   </p>
    //   <div
    //     className="flex items-center mt-3 font-bold pr-10-p pb-10-p h-25-p"
    //     style={{
    //       color: "#555",
    //       letterSpacing: ".5px"
    //     }}
    //   >
    //     <span className=" text-15-p pr-10-p"> Quantity</span>
    //     <input
    //       className="my-0 text-center mx-10-p"
    //       style={{
    //         border: "1px solid #bebebe",
    //         width: "40px"
    //       }}
    //       value={qtyVal}
    //       type="number"
    //       min="1"
    //       max={item.origInstack}
    //       onChange={e => {
    //         setQtyVal(Number(e.target.value));
    //         setUpdateBtn(true);
    //       }}
    //     />
    //     <button
    //       className={`mx-3 ${
    //         updateBtn ? "flex" : "hidden"
    //       } text-white uppercase ${classes.mdl_button}`}
    //       type="button"
    //       style={{
    //         backgroundColor: "#217730",
    //         color: "white",
    //         fontSize: 10,
    //         height: 22,
    //         lineHeight: 0,
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center"
    //       }}
    //       onClick={() => {
    //         handleUpdateProduct(
    //           suppliersBasketState[item.vendorid],
    //           item.vendorid,
    //           item.id,
    //           dispatch,
    //           qtyVal
    //         );
    //         setUpdateBtn(false);
    //       }}
    //     >
    //       Update
    //     </button>{" "}
    //   </div>
    //   <div className="flex flex-col items-end justify-between w-3/12 h-full pb-6 pr-12">
    //     <a
    //       className="cursor-pointer text-20-p"
    //       onClick={() =>
    //         handleUpdateProduct(
    //           suppliersBasketState[item.vendorid],
    //           item.vendorid,
    //           item.id,
    //           dispatch,
    //           0
    //         )
    //       }
    //     >
    //       x
    //     </a>

    //     <p
    //       className="text-center text-15-p"
    //       style={{
    //         color: "#555"
    //       }}
    //     >
    //       <FormattedNumber
    //         value={item.price / priceConvert}
    //         style="currency"
    //         currency={currency}
    //         children={result => <b>{result}</b>}
    //       />
    //     </p>
    //   </div>
    // </div>
    <>

    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Item Quantity is Updated Sucessfully
              </Alert>
            </Snackbar>

    <tr
      className="border border-b"
      style={{ borderBottom: "1px solid #d0d0d0" }}
    >
      
      {/* <td>
        <input
          type="hidden"
          name="basketItems[0].image_path"
          value="store/20180522154/assets/items/thumbnails/AINEGK1D13.jpg"
        />
        <a href="product/merchandise/babyhug-cotton-full-sleeves-sleepsuit-alligator-print-yellow-ainegk1d13">
          {renderProductImage(item.itemcode, item.title)}
        </a>
      </td> */}
      <td data-label="Product Name">
        
          {item.title}{" "}
        <br/><br/>
        <a style={{
          display: "flex",
          alignItems: "center",
          textTransform: "capitalize",
          color: "#ee2614",
          cursor: "pointer"
        }} 
        className="quotedelete"
        onClick={() => handleUpdateProduct(
          suppliersBasketState[item.vendorid].quoteproducts,
          item,
          dispatch,
          0
        )}>
         <span style={{marginRight : "5px"}}>Delete</span> <CgTrash />
        </a>
      </td>

      <td data-label="Product Code">
        <a
          className="quotecompCodeT"
          // href="product/merchandise/babyhug-cotton-full-sleeves-sleepsuit-alligator-print-yellow-ainegk1d13"
        >
          {item.compCode}
        </a>
      </td>
      <td data-label="Quantity">
        <div className="input-group flex">
          <input
            maxLength="5"
            className="form-control text-11-p qtyfi"            
            value={qtyVal}
            type="number"
            min="1"
            max={item.origInstack}
            onChange={e => {
              setQtyVal(Number(e.target.value));
              setUpdateBtn(true);
            }}
          />
          <span className="input-group-btn">
            <button
              type="button"
              title=""
              className="btn btn-primary"
              style={{ height: 33, fontSize: 12, background: "#3f57c6" }}
              onClick={() => {
                handleUpdateProduct(
                  suppliersBasketState[item.vendorid].quoteproducts,
                  item,
                  dispatch,
                  qtyVal
                );
                setUpdateBtn(false);
                setTimeout(() => setOpen(true), 1500)
              }}
            >
              {/* <FiRefreshCcw /> */}
              Save
            </button>
          </span>
        </div>
      </td>
      {/* <td>
        <FormattedNumber
          value={item.price / priceConvert}
          style="currency"
          className="quotecompCodeT"
          currency={currency}
          children={result => <span className="quotecompCodeT">{result}</span>}
        />
      </td>
      <td className="text-center price-text">
        <p className="total totalpricT text-center" id={`total_${item.id}`}>
          <FormattedNumber
            value={(item.price * item.qty) / priceConvert}
            style="currency"
            className="text-11-p text-center"
            currency={currency}
            children={result => <b>{result}</b>}
          />{" "}
        </p>
      </td> */}
      
    </tr>
    </>
  );
};

export default Item;
