import React, { useEffect, useState } from "react";
import Items from "./Items";
import { useSelector, shallowEqual } from "react-redux";
import empty from "../../../assets/img/empty.png";
import home from "../../../assets/img/continue.png";
import { Link } from "gatsby";
import { configQuoteRequest } from "../../../../store-config";
import { useSubscription } from "@apollo/client";
import { GET_CHAT_SUBSCRIPTION } from "../../AC-ProductPage/Components/Chat/queries";

const Quote = ({ params }) => {
  const [finalChatSubscription, setFinalChatSubscription] = useState([]);

  const { data: chatSubscription, loading: loadingChatSubscription } =
    useSubscription(GET_CHAT_SUBSCRIPTION);

  const supplierBasket = useSelector(
    state =>
      state.sessionReducer.suppliersBasket[
        params && typeof params.vid !== "undefined"
          ? params.vid
          : state.sessionReducer.selectedBasket
      ],
    shallowEqual
  );

  useEffect(() => {
    if (chatSubscription) setFinalChatSubscription(chatSubscription);
  }, [chatSubscription]);

  if (configQuoteRequest === "NONE") {
    if (typeof window !== "undefined") {
      window.location = "/";
    }
    return <></>;
  }

  return (
    <div
      className="flex flex-col items-center p-3 mx-auto"
      style={{ width: "95%" }}
    >
      {typeof supplierBasket === "undefined" ||
      Object.keys(supplierBasket).length === 0 ? (
        <>
          <img src={empty} className="img-responsive" alt={`empty`} />
          <Link to="/" className="my-6">
            GoTo Home Page <i className="material-icons">arrow_forward</i>
          </Link>
        </>
      ) : (
        <Items
          loadingChatSubscription={loadingChatSubscription}
          supplierBasket={supplierBasket}
          chatSubscription={finalChatSubscription}
        />
      )}
    </div>
  );
};

export default Quote;
