import React, { useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { I18nContext } from "../../../i18n";
import {
  showChatPopup,
  showChatQuoteBasket
} from "../../../redux/actions/chatActions";
import Item from "./Item";
import axios from "axios";
import { PROJECT_LINK, VID } from "../../../project-config";
import { useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import {
  INIT_CHAT,
  SEND_MESSAGE_BULK
} from "../../AC-ProductPage/Components/Chat/mutations";
import {
  GET_CHAT,
  GET_RECEIVER_ID,
  GET_THE_CURRENT_USER
} from "../../AC-ProductPage/Components/Chat/queries";
import { Modal, TextareaAutosize, Typography, Box } from "@material-ui/core";
import { configQuoteRequest } from "../../../../store-config";
import LoginProfileModal from "../../../components/AC-Header/LoginProfileModal";
import dayjs from "dayjs";
//import { navigate } from "gatsby";
import handleUpdateProduct from "@components/AC-Checkout/handleUpdateProduct";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    boxShadow: "none",
    backgroundColor: theme.palette.background.paper
  },
  colorDefault: {
    boxShadow: "none",
    background: "none"
  }
}));

const Items = ({
  supplierBasket,
  chatSubscription,
  loadingChatSubscription
}) => {
  const [quoteId, setQuoteId] = useState("");
  const [currentChat, setCurrentChat] = useState();
  const [supplierInfo, setSupplierInfo] = useState();

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [messageQuoteBasket, setMessageQuoteBasket] = useState("");
  const login = useSelector(state => state.loginReducer, shallowEqual);

  const [activeDist, setActiveDist] = useState({
    name: supplierBasket?.quoteproducts[0].distName,
    vendorId: supplierBasket?.quoteproducts[0].distVendorId
  });
  const [initChat] = useMutation(INIT_CHAT);
  const { data: currentUserData } = useQuery(GET_THE_CURRENT_USER, {
    variables: {
      email: login.loginName
    },
    skip: login.loginName === ""
  });
  const { data: currentVendorData } = useQuery(GET_RECEIVER_ID, {
    variables: {
      vendorId: activeDist.vendorId
    },
    skip: typeof activeDist.vendorId === "undefined"
  });

  const [showPreModal, setShowPreModal] = useState(false);
  const [showFinishedForQuoteBasket, setShowFinishedForQuoteBasket] =
    useState(false);

  useEffect(() => {
    setActiveDist({
      name: supplierBasket.quoteproducts[0].distName,
      vendorId: supplierBasket.quoteproducts[0].distVendorId
    });
  }, [supplierBasket]);

  useEffect(() => {
    const chat = chatSubscription?.chat?.find(el => {
      return (
        el.chat_users.find(user => user.user.vendor !== null)?.user?.vendor
          ?.supplier_vendorId === activeDist.vendorId
      );
    });
    setCurrentChat(chat);
    const supInfo = chat?.chat_users?.find(
      item => item.user.email !== login.loginName
    ).user;
    setSupplierInfo(supInfo);
  }, [chatSubscription, activeDist.vendorId]);

  const { data: chatData } = useQuery(GET_CHAT);

  const [sendMessageBulk] = useMutation(SEND_MESSAGE_BULK);

  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );
  const itemDetails = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const dispatch = useDispatch();

  const { translate, currency, priceConvert } = useContext(I18nContext);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency
  });

  const [showLoginModal, setShowLoginModal] = useState({
    open: false,
    side: "right"
  });

  const currentQuoteBasket = supplierBasket?.quoteproducts?.filter(
    item => item.distName === activeDist?.name
  );

  const createChatBetweenTwoSides = async (
    loginName,
    firstName,
    lastName,
    recieverId
  ) => {
    const {
      data: { insert_users_one }
    } = await initChat({
      variables: {
        userName: loginName,
        email: loginName,
        firstName: firstName,
        lastName: lastName,
        reciever_id: recieverId
      }
    });

    return insert_users_one;
  };

  const makeRequestToPlaceTheBasket = async quotes => {
    console.log("chatData", quotes);
    let form = new FormData();
    form.append("itemscount", quotes.length);
    form.append("c", quotes[0].currencyid);
    // TODO: FILL me
    form.append(
      "required_by_date",
      dayjs().format("DD/MM/YYYY") // '25/01/2019'
    );
    // form.append("required_by_date", "0923/2021");
    form.append("ship_to_country", "Canada");
    form.append("ship_to_city", "Toronto");
    form.append("ship_to_state", "ON");
    form.append("supplierindex", "1");
    form.append("additional_requirements", messageQuoteBasket);
    // TODO: END OF FILL me

    quotes
      .filter(item => item.distName === activeDist.name)
      .forEach((product, index) => {
        console.log("product", product);
        form.append(`basketItems[${index}].itemId`, product.itemid);
        form.append(`basketItems[${index}].vendorId`, product.vendorid);
        form.append(`basketItems[${index}].itemToProcess`, true);
        form.append(`basketItems[${index}].editMode`, true);
        form.append(`basketItems[${index}].basketItemId`, product.id);
        form.append(`basketItems[${index}].title`, product.title);
        form.append(`basketItems[${index}].quantity`, product.qty);
        form.append(`basketItems[${index}].itemcode`, product.compCode);
        form.append(`basketItems[${index}].suppliercode`, product.distName);
        form.append(`basketItems[${index}].price`, product.price);
        form.append(`basketItems[${index}].ext_price`, product.price);
        // TODO: FILL me OR put generic values
        form.append(`basketItems[${index}].distributorId`, product.distId);
        form.append(`basketItems[${index}].suppliercode`, product.distName);

        form.append(`basketItems[${index}].oldCompCode`, product.compCode);
        form.append(`basketItems[${index}].removal`, false);
        form.append(`basketItems[${index}].supplierindex`, "1");
        product.attributes.forEach((attribute, attrIndex) => {
          const { attributeId, attype, optionId, value } = attribute;
          form.append(
            `basketItems[${index}].attributes[${attrIndex}].attributeId`,
            attributeId
          );
          form.append(
            `basketItems[${index}].attributes[${attrIndex}].attype`,
            attype
          );
          form.append(
            `basketItems[${index}].attributes[${attrIndex}].optionId`,
            optionId
          );
          form.append(
            `basketItems[${index}].attributes[${attrIndex}].value`,
            value
          );
        });
      });

    if (configQuoteRequest === "CHAT") {
      if (
        !chatData?.chat?.some(chat =>
          chat.chat_users.some(
            user => user.user.id === currentVendorData.users[0]?.id
          )
        )
      ) {
        await createChatBetweenTwoSides(
          login.loginName,
          login.firstName,
          login.lastName,
          currentVendorData.users[0]?.id
        );
      }
    }

    try {
      const { data } = await axios({
        method: "post",
        url: `${PROJECT_LINK}/quotebasket.ajx?vid=${VID}`,
        data: form,
        headers: { "Content-Type": "multipart/form-data" }
      });
      console.log(data.__Result.quote_request_id);
      setQuoteId(data.__Result.quote_request_id);
      localStorage.setItem("quote_request_id", data.__Result.quote_request_id);
      return data.__Result.quote_request_id;
    } catch (e) {
      console.log("Error", e);
      return null;
    }
  };

  const openModal = () => {
    makeRequestToPlaceTheBasket(supplierBasket.quoteproducts);
    setShowPreModal(false);
    dispatch(showChatPopup());
    dispatch(showChatQuoteBasket(activeDist));
  };

  const sendMessages = (currentChatId, supplierInfo, customerId, quoteId) => {
    const fullQuoteBasket = currentQuoteBasket
      .map(item => `${item?.qty || 0} ${item?.title || ""}`)
      .join("\n");

    const arrayOfMessages = [
      {
        chat_id: currentChatId,
        sender_id: supplierInfo?.id,
        content: `You have requested a quote for:
        ${fullQuoteBasket}`,
        quote_request_id: quoteId
      },
      {
        chat_id: currentChatId,
        sender_id: supplierInfo?.id,
        content: `While we wait to connect to ${supplierInfo?.vendor?.brand} can you advise if you have any Additional Requirments for this quote request?`,
        quote_request_id: quoteId
      }
    ];

    if (messageQuoteBasket && messageQuoteBasket.trim().length !== 0) {
      arrayOfMessages.push({
        chat_id: currentChatId,
        content: messageQuoteBasket,
        sender_id: customerId,
        quote_request_id: quoteId
      });
    }

    sendMessageBulk({ variables: { messages: arrayOfMessages } })
      .then(() => {
        setShowFinishedForQuoteBasket(true);
        setMessageQuoteBasket("");
      })
      .then(() => {
        const item = supplierBasket.quoteproducts.filter(
          basketItem => basketItem.distVendorId === activeDist.vendorId
        );
        item.forEach(el => handleUpdateProduct(item, el, dispatch, 0));
      });
  };

  return (
    <div
      className="flex w-full justify-center mb-8 mt-10-p"
      style={{ width: "95%" }}
    >
      <div className="flex flex-col w-11/12">
        {/* <h2
          className="text-black mt-10 mb-3 bg-white w-full text-center text-20-p bg-clip-padding p-20-p"
          title="Your Cart"
          style={{
            lineHeight: "1em"
          }}
        >
          Quote Basket
        </h2> */}

        <AppBar
          position="static"
          color="default"
          style={{ background: "none", boxShadow: "none", zIndex: "0" }}
        >
          <Tabs
            TabIndicatorProps={{ style: { background: "#fff" } }}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {supplierBasket.quoteproducts.length > 0 &&
              supplierBasket.quoteproducts
                .filter(
                  (v, i, a) => a.findIndex(t => t.distName === v.distName) === i
                )
                .map((item, i) => (
                  <div style={{ position: "relative" }}>
                    <p
                      key={i}
                      className={`cursor-pointer text22-p sellerTabs ${
                        (i !== 0 ? "ml-20-p" : "ml-0",
                        activeDist.name === item.distName ? "sellerActive" : "")
                      }`}
                      onClick={() => {
                        console.log("item", item);
                        setActiveDist({
                          name: item.distName,
                          vendorId: item.distVendorId
                        });
                      }}
                      style={{
                        backgroundColor:
                          activeDist.name === item.distName
                            ? "#fff"
                            : "#F7F9FA",
                        color:
                          activeDist.name === item.distName
                            ? "#4A4A4A"
                            : "#4A4A4A"
                      }}
                    >
                      <Tab label={item.distName} {...a11yProps(0)} />
                    </p>
                  </div>
                ))}{" "}
          </Tabs>
        </AppBar>

        <div className="flex flex-col">
          <div>
            <div className="flex w-full"></div>
          </div>
          <table className="table table-bordered mt-8 quotebasket">
            <thead className="thead-form">
              <tr>
                {/* <td style={{ fontSize: 15, textTransform: "capitalize" }}>
                  Image
                </td> */}
                <td style={{ fontSize: 15, textTransform: "capitalize" }}>
                  Product Name
                </td>
                <td style={{ fontSize: 15, textTransform: "capitalize" }}>
                  Product Code
                </td>
                <td style={{ fontSize: 15, textTransform: "capitalize" }}>
                  Quantity
                </td>
                {/* <td style={{ fontSize: 15, textTransform: "capitalize" }}>
                  Unit Price
                </td>
                <td style={{ fontSize: 15, textTransform: "capitalize" }}>
                  Total
                </td> */}
              </tr>
            </thead>
            <tbody className="tbody-form">
              {supplierBasket.quoteproducts.length > 0 &&
                supplierBasket.quoteproducts
                  .filter(item => item.distName === activeDist.name)
                  .map(item => <Item key={item.itemid} item={item} />)}
            </tbody>
          </table>
        </div>
        {securityTokenState ? (
          supplierBasket.quoteproducts.length > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              {configQuoteRequest === "MESSAGE" && (
                <Box flex={0.9}>
                  <Typography
                    style={{
                      marginBottom: 10
                    }}
                  >
                    Do you have any additional requirements?
                  </Typography>
                  <TextareaAutosize
                    value={messageQuoteBasket}
                    onChange={event =>
                      setMessageQuoteBasket(event.target.value)
                    }
                    className="messageQuoteBasket"
                    minRows={4}
                    placeholder="Type your message in here ..."
                  />
                </Box>
              )}
              <button
                disabled={loadingChatSubscription}
                className="addToCartBtn"
                style={{
                  width: "max-content",
                  alignSelf: "flex-end",
                  display: "flex",
                  padding: "10px 20px",
                  border: "2px solid #3f57c6",
                  borderRadius: "24px",
                  justifyContent: "flex-end"
                }}
                onClick={async () => {
                  if (configQuoteRequest === "CHAT") setShowPreModal(true);
                  else {
                    const quote_req_id = await makeRequestToPlaceTheBasket(
                      supplierBasket.quoteproducts
                    );
                    if (currentChat) {
                      sendMessages(
                        currentChat.id,
                        supplierInfo,
                        currentUserData.users[0].id,
                        quote_req_id
                      );
                    } else {
                      const newUserData = await createChatBetweenTwoSides(
                        login.loginName,
                        login.firstName,
                        login.lastName,
                        currentVendorData.users[0]?.id
                      );
                      sendMessages(
                        newUserData.chat_users.at(-1).chat_id,
                        currentVendorData.users[0],
                        newUserData.chat_users[0].user_id,
                        quote_req_id
                      );
                    }
                  }
                  //navigate("/");
                }}
              >
                Submit quote request
              </button>
            </Box>
          )
        ) : (
          <button
            className="addToCartBtn"
            style={{
              width: "max-content",
              alignSelf: "flex-end",
              display: "flex",
              padding: "10px 20px",
              border: "2px solid #3f57c6",
              borderRadius: "24px",
              justifyContent: "flex-end"
            }}
            onClick={() =>
              setShowLoginModal({
                open: true,
                side: "right"
              })
            }
          >
            Login to Request a Quote
          </button>
        )}
      </div>
      <LoginProfileModal modalState={showLoginModal} />
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        open={showFinishedForQuoteBasket}
        onClose={() => setShowFinishedForQuoteBasket(false)}
      >
        <div className="added-to-cart-modal-wrapper itemafter">
          <span
            style={{
              color: "black",
              fontSize: "30px",
              fontWeight: "normal",
              cursor: "pointer",
              width: "20px",
              right: "0px",
              top: "0px",
              position: "absolute"
            }}
            onClick={() => setShowFinishedForQuoteBasket(false)}
          >
            ×
          </span>

          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img
              width="100"
              src="https://ik.imagekit.io/ofb/dbz/check_mark_1_DqG9gmsC2.gif"
            />
          </div>

          <p
            id="simple-modal-description"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
              fontSize: "17px",
              fontWeight: "400",
              textTransform: "capitalize"
            }}
          >
            Your Quote Request: <b style={{ color: "#173ef2" }}>{quoteId}</b>{" "}
            has been Submitted.
          </p>
          {/* <button
            className=""
            onClick={() => setShowFinishedForQuoteBasket(false)}
            style={{ background: "none",
            fontWeight: "bold",
            margin: "10px",
            fontSize: "18px" }}
          >
            {translate("compItems.goBackButton")}
          </button> */}
        </div>
      </Modal>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        open={showPreModal}
        onClose={() => setShowPreModal(false)}
      >
        <div className="added-to-cart-modal-wrapper itemafter">
          <span
            style={{
              color: "black",
              fontSize: "30px",
              fontWeight: "normal",
              cursor: "pointer",
              width: "20px",
              right: "25px",
              top: "10px",
              position: "absolute"
            }}
            onClick={() => setShowPreModal(false)}
          >
            ×
          </span>
          <button
            onClick={() => openModal()}
            className="addToCartBtn"
            style={{
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "14px"
            }}
          >
            Chat with seller
          </button>
          {/*  </a> */}
          <button
            className="addToCartBtn"
            onClick={() => setShowPreModal(false)}
            style={{
              backgroundColor: "#31ac26",
              color: "#fff",
              fontSize: "14px"
            }}
          >
            {translate("compItems.goBackButton")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Items;
