import React from "react";

import Layout from "../../layout";
import SEO from "../../other/seo";
import Quote from "../../components/AC-Checkout/Quote/Quote";

const vid = ({ params }) => {
  return (
    <Layout params={params}>
      <SEO title="Basket" />
      <Quote params={params} />
    </Layout>
  );
};

export default vid;
